
export const deskmatData = [
   {
      "id": "set02-collection",
      "title": 'SET02 Collection',
      "desc": "Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../imgs/MyProducts/set02-collection.png"),
      "links": { 
         "small":"",
         "medium": "",
         "large": "https://sulce.shop/collections/set02",
      },
      "type": "all, deskmat, highlight", 
      "preview": ""
   },
   {
      "id": "desk-mat-collection",
      "title": 'Desk Mat. Collection',
      "desc": "Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../imgs/MyProducts/desk-mat-collection.png"),
      "links": { 
         "small":"",
         "medium": "",
         "large": "https://sulce.shop/collections/desk-mat",
      },
      "type": "all, deskmat, highlight", 
      "preview": ""
   },
   {
      "id": "set01-collection",
      "title": 'SET01 Collection',
      "desc": "Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../imgs/MyProducts/set01-collection.png"),
      "links": { 
         "small":"",
         "medium": "",
         "large": "https://sulce.shop/collections/set01",
      },
      "type": "all, deskmat", 
      "preview": "https://www.youtube.com/embed/alj_KFRRsyk"
   },
]
