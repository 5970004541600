import {React, useState} from 'react'
import { motion } from "framer-motion"
import Modal from 'react-modal';

import '../styles/Wallpapers.scss';
import { WallpaperCard } from './index.js';
import { FaArrowRight, FaTimes } from "react-icons/fa";


export const Wallpapers = ({wallpapersData}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  let wallpaperSearchCount = 0;

  let ShortWallpapersList = wallpapersData.map((element, id) => {
    if(element.type.toLowerCase().includes('highlight')) {
      return <WallpaperCard
          key={element.id}
          id={element.id}
          title={element.title}
          desc={element.desc}
          className={element.className}
          img={element.img}
          url={element.url}
      />
    } else {
      return <></>
    }
  })

  let tempFullWallpapersList = wallpapersData.map((element, id) => {
        return <WallpaperCard
              key={element.id}
              id={element.id}
              title={element.title}
              desc={element.desc}
              className={element.className}
              img={element.img} 
              url={element.url}
          />

  })
  const [FullWallpapersList, setFullWallpapersList] = useState(tempFullWallpapersList);
  const [StaticFullWallpapersList] = useState(tempFullWallpapersList)
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'var(--black)'
    },
  };

  function openModal() {
    setIsOpen(true);
    setFullWallpapersList(StaticFullWallpapersList)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // console.log("console open")
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleWallpaperSearch = (e) => {
    wallpaperSearchCount = 0
    tempFullWallpapersList = wallpapersData.map((element, id) => {
      if(element.title.toLowerCase().includes(e.target.value.toLowerCase())
      || element.desc.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        wallpaperSearchCount++;
        return <WallpaperCard
            key={element.id}
            id={element.id}
            title={element.title}
            desc={element.desc}
            className={element.className}
            img={element.img} 
            url={element.url}
          />
      } else {
        return <></>
      }
    })
    if(wallpaperSearchCount === 0) {
      tempFullWallpapersList = <div className="w-100 h-100 d-flex align-items-center justify-content-center"><p>No Results Found</p></div>
    }

    setFullWallpapersList(tempFullWallpapersList)
  }


   return (
    <div id="wallpapers">
      <h3>Wallpapers</h3>
      <div className="wallpapers-container">
      {ShortWallpapersList}
      <div></div>
      <div className="w-100 mt-3 d-flex justify-content-end">
        <div className="open-modal-text" onClick={openModal}>
          View All Wallpapers  <FaArrowRight id="FaArrowRight"/>
        </div>
      </div>
    </div>
    <div>
      <Modal
        id='wallpaper-modal'
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Full Wallpapers List Modal"
        ariaHideApp={false}
      >
        <div className="wallpaper-modal-header w-100 d-flex justify-content-between">
          <div id="wallpaper-search-container" className='d-flex flex-row w-75'>
            <h2>All Wallpapers</h2>
            <div id="wallpaper-container">
              <input id="wallpaper-search" type="text" placeholder="Search..." onChange={handleWallpaperSearch} onKeyUp={handleWallpaperSearch}/>
            </div>
          </div>
          <div className="modal-close-container">
            <FaTimes className="FaTimes" onClick={closeModal} />
          </div>
        </div>
        <motion.div
          initial={{y: '50vh'}}
          animate={{y: '0'}}
          exit={{y: '50vh'}}
          transition={{type: "spring",stiffness: 50}}
          className="wallpapers-container px-1">
          {FullWallpapersList}
        </motion.div>
          
      </Modal>
    </div>
  </div>
   )
}