export const headerData = [
   // {
   //    "id": "bf-deals",
   //    "className": "", 
   //    "title": "BLACK FRIDAY DEALS",
   //    "desc": "2024 Black Friday Tech Deals.",
   //    "img": require("../imgs/Affiliates/bf-deals.png"),
   //    "url": "https://sulce.notion.site/Black-Friday-Tech-Deals-2024-1495e2c3d17080f9a03ee9a034ed7d45",
   //    "type": "highlight"
   // },
   {
      "id": "3d-prints-button",
      "className": "", 
      "title": "DESK MATS & 3D PRINT SHOP",
      "desc": "Sulce Shop - Desk Mats, Shirts, 3D Prints and All My Products.",
      "img": require("../imgs/sulce-shop.png"),
      "url": "https://sulce.shop",
      "type": "highlight"
   },
   {
      "id": "3d-prints-button",
      "className": "", 
      "title": "3D MODELS - THANGS",
      "desc": "Sulce Studio - All My 3D Print Models.",
      "img": require("../imgs/sulce-studio.png"),
      "url": "https://thangs.com/designer/SulceStudio",
      "type": "highlight"
   },
   {
      "id": "amazon-store-front-button",
      "className": "", 
      "title": "AMAZON STORE FRONT",
      "desc": "All My Amazon Products.",
      "img": require("../imgs/Affiliates/amazon-logo.jpg"),
      "url": "https://www.amazon.com/shop/sulce",
      "type": "highlight"
   },
   // {
   //    "id": "wallpapers-button",
   //    "className": "", 
   //    "title": "WALLPAPERS",
   //    "desc": "All My Wallpapers.",
   //    "img": require("../imgs/Wallpapers/Stellar.png"),
   //    "url": "https://sulce.gumroad.com",
   //    "type": "highlight"
   // },
   // {
   //    "id": "bored-in-space",
   //    "className": "", 
   //    "title": "Bored in Space - Game",
   //    "desc": "Chrome Extension Game by Sulce Studio.",
   //    "img": require("../imgs/MyProducts/bored-in-space.png"),
   //    "url": "https://chromewebstore.google.com/detail/bored-in-space/achgacheifcocdopkamkibknnggofoej",
   //    "type": "highlight"
   // },
]