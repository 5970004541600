import {React, } from 'react'
import '../styles/BuyMeACoffee.scss';

export const BuyMeACoffee = () => {
   function openWindow() {
      window.open('https://sulce.shop/pages/buy-me-a-coffee', '_blank', 'width=600,height=800')
   }
   
   return (
      <div id="buy-me-a-coffee" onClick={openWindow}>
         <div id="buy-me-a-coffee-container">
            <h3 className='m-0'>Buy Me A Coffee &#9749;</h3>
         </div>
      </div>
   )
}
