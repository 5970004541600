
export const myProductListData = [
   {
      "id": "dr-stream-deck-profile",
      "title": "Davinci Resolve Stream Deck Profile",
      "desc": "DaVinci Resolve Shortcuts Pack for Stream Deck MK.2..",
      "className": "", 
      "img": require("../imgs/MyProducts/stream-deck-profile.png"),
      "url":"https://sulce.gumroad.com/l/dr-stream-deck-profile",
      "type": "all, software, other", 
      "preview": "" 
   },
   {
      "id": "basic-dr-stream-deck-profile",
      "title": "Basic Davinci Resolve Profile",
      "desc": "Free Stream Deck Profile - DR Basics Free Stream Deck Profile..",
      "className": "", 
      "img": require("../imgs/MyProducts/DRStream-Deck-Profile.png"),
      "url":"https://sulce.gumroad.com/l/dr-basics-stream-deck-profile",
      "type": "all, software, other", 
      "preview": "https://www.youtube.com/embed/aFmC-HiDcns" 
   },
   {
      "id": "all-wallpapers",
      "title": "Wallpapers",
      "desc": "Alll my Wallpapers..",
      "className": "", 
      "img": require("../imgs/Wallpapers/Stellar.png"),
      "url":"https://sulce.gumroad.com",
      "type": "all, other", 
      "preview": "" 
   },
   {
      "id": "set02-collection",
      "title": 'Desk Mat - SET02 Collection',
      "desc": "Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../imgs/MyProducts/set02-collection.png"),
      "url": "https://sulce.shop/collections/set02",
      "type": "all, deskmat", 
      "preview": ""
   },
   {
      "id": "desk-mat-collection",
      "title": 'Desk Mat. Collection',
      "desc": "Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../imgs/MyProducts/desk-mat-collection.png"),
      "url": "https://sulce.shop/collections/desk-mat",
      "type": "all, setup",
      "preview": ""
   },
   {
      "id": "set01-collection",
      "title": 'Desk Mat - SET01 Collection',
      "desc": "Desk Mat | 3mm Thick.",
      "className": "", 
      "img": require("../imgs/MyProducts/set01-collection.png"),
      "url": "https://sulce.shop/collections/set01",
      "type": "all, deskmat", 
      "preview": "https://www.youtube.com/embed/alj_KFRRsyk"
   },
]
