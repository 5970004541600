import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from "react-icons/fa";
import { TbReload } from "react-icons/tb";

import '../styles/KeyboardTester.scss'; // We'll define the CSS later

// Array representing the key rows
const KEYBOARD_LAYOUT = [
  ['Insert', 'Delete','Home', 'End', 'PageUp', 'PageDown'],
  ['Esc', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'],
  ['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', 'Backspace'],
  ['Tab', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '[', ']', '\\'],
  ['CapsLock', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ';', '\'', 'Enter'],
  ['Shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', '/', 'Shift'],
  ['Ctrl', 'Win', 'Alt', 'Space', 'Alt', 'Fn', 'Ctrl', '←', '↓', '↑', '→'],
];

const normalizeKey = (key) => {
  // Normalize the key to handle known differences
  const specialKeysMap = {
    ' ': 'Space',
    'Backspace': 'Backspace',
    'Enter': 'Enter',
    'Shift': 'Shift',
    'Control': 'Ctrl',
    'Alt': 'Alt',
    'Meta': 'Win',
    'CapsLock': 'CapsLock',
    'Tab': 'Tab',
    'ArrowUp': '↑',
    'ArrowDown': '↓',
    'ArrowLeft': '←',
    'ArrowRight': '→',
    'Insert': 'Insert',
    'Home': 'Home',
    'PageUp': 'PageUp',
    'Delete': 'Delete',
    'End': 'End',
    'PageDown': 'PageDown',
    'Escape': 'Esc',
  };
  
  return specialKeysMap[key] || key.toUpperCase(); // Use uppercase for letter keys
};

export const KeyboardTester = () => {
  const [realTimeKeys, setRealTimeKeys] = useState({}); 
  const [persistentKeys, setPersistentKeys] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  
  // Load the sound
  const keyPressSound = new Audio('/sounds/x.mp3');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'var(--black)'
    },
  };

  const handleKeyDown = (event) => {
    const normalizedKey = normalizeKey(event.key);

    // Play sound when a key is pressed
    keyPressSound.currentTime = 0; // Reset the sound to allow rapid presses
    keyPressSound.play(); // Play the sound

    // Prevent default behavior for F5 (reload) and ESC (modal close)
    if (normalizedKey.startsWith('F')) {
      event.preventDefault();
      event.stopPropagation();
    }
    // Add key to real-time highlights
    setRealTimeKeys((prevKeys) => ({
      ...prevKeys,
      [normalizedKey]: true,
    }));

    setTimeout(() => {
      setPersistentKeys((prevKeys) => ({
        ...prevKeys,
        [normalizedKey]: true,
      }));

      // Remove from real-time highlights
      setRealTimeKeys((prevKeys) => {
        const updatedKeys = { ...prevKeys };
        delete updatedKeys[normalizedKey];
        return updatedKeys;
      });
    }, 200);  // Delay before transitioning to persistent highlight
  };

  const resetKeyboard = () => {
    setRealTimeKeys({});
    setPersistentKeys({});
  };

  useEffect(() => {
    if(modalIsOpen) {
      window.addEventListener('keydown', handleKeyDown);
  
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    } 
  }, [modalIsOpen]);

  function openModal() {
    setIsOpen(true);
  }


  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // console.log("modal is open")
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div id="keyboard-tester">
      <div id="keyboard-container" onClick={openModal}>
        <h3 className='m-0'>Keyboard Tester &#128187;</h3>
      </div>
      <Modal
        id='keyboard-modal'
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {}}
        style={customStyles}
        contentLabel="Full Products List Modal"
        ariaHideApp={false}
      >
        <div id="keyboard-header-container">
          <h2>Keyboard Tester - Beta 1.0</h2>
          <div className="modal-close-container">
            <FaTimes className="FaTimes" onClick={closeModal} />
          </div>
        </div>
        <div className="keyboard">
          {KEYBOARD_LAYOUT.map((row, rowIndex) => (
            <div className="keyboard-row" key={rowIndex}>
              {row.map((key, keyIndex) => (
                <div
                className={`key ${realTimeKeys[key] ? 'active' : persistentKeys[key] ? 'persistent' : ''}`}
                  key={keyIndex}
                >
                  {key}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className='reload-container'>
          <TbReload className="TbReload" onClick={resetKeyboard}/>
        </div>
      </Modal>
    </div>
  );
};
