import React, { useState  } from 'react'
import { motion } from "framer-motion"

import '../styles/Footer.scss';
import '../styles/SocialIcon.scss';
import { SocialIcon } from './index.js';

export const Footer = ({url, socialData}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  
  const handleResize = () => {
    setWindowSize(window.innerWidth)
  }

  window.addEventListener('resize', handleResize)

  let firstTwoSocialData = socialData.slice(0, 2);

  const firstTwoIconsElement = firstTwoSocialData.map((element, id) => {
    return <a key={element.id} className='separate_icons' style={{"cursor":"pointer"}} href={element.url} target='_blank' rel="noreferrer">
      <SocialIcon
       id={element.id} 
       className={element.className}
       icon={element.icon}
       url={element.url}
       checkLink={false}
      />
      <p>{element.id}</p>
    </a>
 })

 let secondTwoSocialData = socialData.slice(2, 4);

  const secondTwoIconsElement = secondTwoSocialData.map((element, id) => {
    return <a key={element.id} className='separate_icons' style={{"cursor":"pointer"}} href={element.url} target='_blank' rel="noreferrer">
      <SocialIcon
       id={element.id} 
       className={element.className}
       icon={element.icon}
       url={element.url}
       checkLink={false} 
      />
      <p>{element.id}</p>
    </a>
 })



  return (
    <div id='footer'>
      <div id="top-layer-container">
         <div id="footer-top-layer">
            {(windowSize > 550) 
              ? <>
                  <div id="created-by" className="w-25 seperate_icons_container justify-content-start social_container">
                    Created by sulce
                  </div>
                  <a id="footer-qoute" href={url} target='_blank' rel="noreferrer"  className="d-flex justify-content-end align-items-center"><h3>Come find, explore, and share tech with me</h3></a>
                  <div className="seperate_icons_container justify-content-end social_container">
                    {firstTwoIconsElement}
                    {secondTwoIconsElement}
                  </div>
                </> 
              
              : <>
                  <div className="seperate_icons_container justify-content-center social_container">
                      {firstTwoIconsElement}
                      {secondTwoIconsElement}
                  </div>
                  <motion.a 
                    id="footer-qoute" href={url} target='_blank' rel="noreferrer"  
                    className="d-flex justify-content-end align-items-center" 
                    whileHover={{scale: 1.01}} whileTap={{scale: 0.90}}>
                      <h3>Come find, explore, and share tech with me</h3>
                  </motion.a>
                  <div id="created-by" className="seperate_icons_container justify-content-start social_container">
                    Created by SULCE
                  </div>
                </> 
            }
         </div>
      </div>
    </div>
  )
}

// export default Footer
