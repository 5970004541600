
export const wallpaperData = [
   {
      "id": "tiles",
      "title": "Tiles",
      "desc": "March, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Tiles.png"),
      "url": "https://sulce.gumroad.com/l/tiles",
      "type": "highlight"
   },
   {
      "id": "wallpaper",
      "title": "Wallpaper",
      "desc": "July, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Wallpaper.png"),
      "url": "https://sulce.gumroad.com/l/wallpaper",
      "type": ""
   },
   {
      "id": "vibrant-sunset",
      "title": "Vibrant Sunset",
      "desc": "June, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Vibrant-Sunset.png"),
      "url": "https://sulce.gumroad.com/l/vibrant-sunset",
      "type": ""
   },
   {
      "id": "oceanside-motivation",
      "title": "Oceanside Motivation",
      "desc": "May, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Oceanside-Motivation.png"),
      "url": "https://sulce.gumroad.com/l/oceanside-motivation",
      "type": ""
   },
   {
      "id": "spring-motivation",
      "title": "Spring Motivation",
      "desc": "April, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Spring-Motivation.png"),
      "url": "https://sulce.gumroad.com/l/spring-motivation",
      "type": "highlight"
   },
   {
      "id": "calm-night-elite",
      "title": "Calm Night Elite",
      "desc": "March, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Calm-Night-Elite.png"),
      "url": "https://sulce.gumroad.com/l/calm-night",
      "type": ""
   },
   {
      "id": "calm-night",
      "title": "Calm Night",
      "desc": "March, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Calm-Night.png"),
      "url": "https://sulce.gumroad.com/l/calm-night",
      "type": ""
   },
   {
      "id": "stellar-elite",
      "title": "Stellar Elite",
      "desc": "February, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Stellar-Elite.png"),
      "url": "https://sulce.gumroad.com/l/stellar",
      "type": ""
   },
   {
      "id": "stellar",
      "title": "Stellar",
      "desc": "February, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Stellar.png"),
      "url": "https://sulce.gumroad.com/l/stellar",
      "type": ""
   },
   {
      "id": "911-elite",
      "title": "911 Elite",
      "desc": "January, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/911-Elite.png"),
      "url": "https://sulce.gumroad.com/l/911",
      "type": ""
   },
   {
      "id": "911",
      "title": "911",
      "desc": "January, 2024 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/911.png"),
      "url": "https://sulce.gumroad.com/l/911",
      "type": ""
   },
   {
      "id": "snow-1-2",
      "title": "Snow 1.2",
      "desc": "December, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Snow-1-2.png"),
      "url": "https://sulce.gumroad.com/l/snow-1-2",
      "type": ""
   },
   {
      "id": "snow",
      "title": "Snow",
      "desc": "December, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Snow.png"),
      "url": "https://sulce.gumroad.com/l/snow",
      "type": ""
   },
   {
      "id": "waves",
      "title": "Waves",
      "desc": "November, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Waves.png"),
      "url": "https://sulce.gumroad.com/l/waves",
      "type": ""
   },
   {
      "id": "autumn",
      "title": "Autumn",
      "desc": "October, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Autumn.png"),
      "url": "https://sulce.gumroad.com/l/autumn",
      "type": ""
   },
   {
      "id": "cozy-supra",
      "title": "Cozy Supra",
      "desc": "September, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Cozy-Supra.png"),
      "url": "https://sulce.gumroad.com/l/cozy-supra",
      "type": ""
   },
   {
      "id": "clouds",
      "title": "Clouds",
      "desc": "August, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Clouds.png"),
      "url": "https://sulce.gumroad.com/l/clouds",
      "type": ""
   },
   {
      "id": "cozy-drive",
      "title": "Cozy Drive",
      "desc": "July, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Cozy-Drive.png"),
      "url": "https://sulce.gumroad.com/l/cozy-drive",
      "type": ""
   },
   {
      "id": "gtr-elite",
      "title": "GTR ELITE",
      "desc": "Mid June, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/GTR-ELITE.png"),
      "url": "https://sulce.gumroad.com/l/gtr-elite",
      "type": ""
   },
   {
      "id": "gtr",
      "title": "GTR",
      "desc": "June, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/GTR.png"),
      "url": "https://sulce.gumroad.com/l/gtr",
      "type": ""
   },
   {
      "id": "city-sky",
      "title": "City Sky",
      
      "desc": "May, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/City-Sky.png"),
      "url": "https://sulce.gumroad.com/l/citysky",
      "type": ""
   },
   {
      "id": "april-colors",
      "title": "April Colors",
      "desc": "April, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/April-Colors.png"),
      "url": "https://sulce.gumroad.com/l/aprilcolors",
      "type": ""
   },
   {
      "id": "as-the-sunsets",
      "title": "As The Sunsets",
      "desc": "March, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/As-The-Sunsets.png"),
      "url": "https://sulce.gumroad.com/l/asthesunsets",
      "type": ""
   },
   {
      "id": "wavy-clouds-vol-1",
      "title": "Wavy Clouds Vol 1",
      "desc": "February, 2023 Release",
      "className": "", 
      "img": require("../imgs/Wallpapers/Wavy-Clouds-vol-1.png"),
      "url": "https://sulce.gumroad.com/l/aprilcolors",
      "type": ""
   },
]