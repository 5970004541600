import { FaTiktok, FaYoutube, FaInstagram, FaDiscord } from "react-icons/fa"; // Font Awesome 
import '../styles/SocialIcon.scss';

export const socialIconData = [
   {
      "id": "Youtube",
      "className": "", 
      "icon": <FaYoutube />,
      "url": "https://youtube.com/@sulcetech"
   },
   {
      "id": "Discord",
      "className": "", 
      "icon": <FaDiscord />,
      "url": "https://discord.gg/tz5PBBrPGe"
   },
   {
      "id": "Instagram",
      "className": "", 
      "icon": <FaInstagram />,
      "url": "https://www.instagram.com/sulcetech"
   },
   {
      "id": "TikTok",
      "className": "", 
      "icon": <FaTiktok />,
      "url": "https://www.tiktok.com/@sulcetech"
   }
]